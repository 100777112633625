<template>
  <main class="value">
    <Breadcrumb />
    <PageHeader
      title="History & Traditions"
      backgroundImage="history/29565-048.jpg"
      backgroundColor="transparent"
      textColor="white"
      overlay="medium"
      align="center"
      style="background-position: bottom center"
      size="lg"
    >
    </PageHeader>

    <Section
      type="text"
      imgSide="right"
      imgURL="history/35310-115.jpg"
      class="text-center bg-light bg-diagonal--light"
    >
      <p>
        As the birthplace of public higher education in America, the University
        of Georgia has a rich history that spans more than 230 years. That
        history was steeped in tradition that has flourished and evolved over
        time. Now, UGA students participate in a diverse array of time-honored
        customs and even leave behind some traditions of their own.
      </p>
    </Section>

    <Section
      type="standard"
      imgSide="right"
      imgURL="history/35310-115.jpg"
      class="text-white"
      color="bg-red bg-diagonal--dark"
    >
      <h3>Ringing the Chapel Bell</h3>
      <p>
        A bell tower originally crowned the roof of the Chapel, but in 1913, it
        was removed due to structural issues and housed in the wooden tower
        behind the Chapel. The bell rang for religious services, for the
        beginning and ending of classes, and in emergencies.
      </p>
      <p>
        Now the Bulldog family rings the bell to mark athletic victories,
        academic accomplishments, and other special occasions — and can be heard
        throughout downtown Athens and North Campus.
      </p>
    </Section>

    <section style="margin: 6.5rem 0">
      <div class="row container-lg g-5 mx-auto">
        <div class="col-md-6" data-aos="fade-up">
          <QuickFact
            imageURL="history/36988-021.jpg"
            heading="Visiting the Arch"
          >
            <p>
              One of the most popular photo spots on campus, the iconic Arch is
              the symbol of the University of Georgia. The Arch was installed in
              the 1800s and still stands as the gateway between North Campus and
              downtown Athens. Recognized as one of the school’s finest icons,
              its three pillars represent the virtues of wisdom, justice and
              moderation. On strolls through North Campus or when your family
              comes to visit, be sure to snap a picture by the Arch.
            </p>
          </QuickFact>
        </div>
        <div class="col-md-6" data-aos="fade-up">
          <QuickFact
            imageURL="history/37355-028.jpg"
            heading="Light up Sanford"
          >
            <p>
              One of UGA’s newest traditions, ‘Light up Sanford’ began in the
              2016 football season. Now, fans anticipate the beginning of the
              fourth quarter of any night game when they turn on their phone
              flashlights as the Redcoat Band’s “Krypton Fanfare” plays.
            </p>
            <p>
              In 2019, UGA replaced the halogen lights with computer-controlled
              LED lights. When the cellphone lights go up, the stadium lights
              go down, creating an even more dramatic effect.
            </p>
          </QuickFact>
        </div>
      </div>
    </section>

    <section>
      <Section
        type="standard"
        imgSide="left"
        imgURL="history/38971-022.jpg"
        color="bg-black bg-diagonal--light"
        class="text-white"
      >
        <h3>Freshman Welcome &#8212; Super &ldquo;G&rdquo;</h3>
        <p>
          Freshman Welcome is an annual event, hosted the day before the first
          day of classes. Along with meeting Hairy Dawg and President Morehead,
          the newest class of Bulldogs form the Power “G” on the field at
          Sanford Stadium for a memorable photo. The students also learn our
          school chants and cheers and get a taste of what it means to be a
          Bulldog for the first time.
        </p></Section
      >
    </section>
    <section class="my-5">
      <div class="container">
        <h2 class="mb-4 fs-1 text-center">
          The History of The University of Georgia
        </h2>
        <ul class="timeline">
          <li
            v-for="event in timeline"
            :key="event.year"
            class="timeline__event"
            data-aos="fade"
          >
            <div class="timeline__event-year">{{ event.year }}</div>
            <div class="timeline__event-description">
              {{ event.description }}
            </div>
          </li>
        </ul>
      </div>
    </section>
  </main>
</template>

<script>
// @ is an alias to /src
import Breadcrumb from '@/components/Breadcrumb.vue';
import PageHeader from '@/components/PageHeader.vue';
// import Figure from '@/components/Figure.vue';
import QuickFact from '@/components/QuickFact.vue';
// import Factoid from '@/components/Factoid.vue';
import Section from '@/components/Section.vue';
// import YoutubeVideo from 'vue3-youtube';
// import VideoBackground from '@/components/VideoBackground.vue';

export default {
  name: 'Value',
  metaInfo: {
    title: 'UGA History and Traditions',
    description:
      'The University of Georgia’s diverse and ever-evolving set of traditions stem from a rich history that dates back to our founding more than 230 years ago.',
    og: {
      image: '',
      title: '',
      description: '',
    },
  },
  data() {
    return {
      timeline: [
        {
          year: '1785',
          description:
            'The state General Assembly incorporated the University of Georgia on Jan. 27, 1785. Georgia became the first to charter a state-supported university, and UGA became the birthplace of public higher education in America.',
        },
        {
          year: '1786',
          description:
            'In 1786, Abraham Baldwin, who drafted the charter for the university, was selected as the university’s first president. Baldwin, a native of Connecticut and a graduate of Yale University, was one of two Georgians to sign the U.S. Constitution in 1787.',
        },
        {
          year: '1801',
          description:
            'The university officially opened in 1801 when a committee of the board of trustees selected a land site. John Milledge, later a Georgia governor, purchased and gave to the board of trustees the chosen tract of 633 acres on the banks of the Oconee River in northeast Georgia. Josiah Meigs was named president of the university, and work began on the first building, initially called Franklin College, in honor of Benjamin Franklin. That building is now known as Old College. The university became known as Franklin College, a name that stuck for more than 50 years.',
        },
        {
          year: '1804',
          description: 'The university graduated its first class in 1804.',
        },
        {
          year: '1863',
          description:
            'In 1863, the university closed its doors due to the Civil War. It didn’t reopen until 1866.',
        },
        {
          year: '1872',
          description:
            'In 1872, the university was designated as a land-grant institution under the 1862 Morrill Act.',
        },
        {
          year: '1892',
          description:
            'In 1892, chemistry professor and alumnus Charles Herty organized and coached UGA’s first intercollegiate football team.',
        },
        {
          year: '1918',
          description:
            'In 1918, UGA admitted women as students for the first time.',
        },
        {
          year: '1931',
          description:
            'In 1931, the General Assembly of Georgia placed all state-supported higher education institutions, including UGA, under the jurisdiction of a single board. This organization, known as the University System of Georgia, is governed by the board of regents.',
        },
        {
          year: '1942',
          description:
            'In 1942, UGA won its first footbal national championship , although the Georgia Bulldogs and the Ohio State Buckeyes both claimed titles this year.',
        },
        {
          year: '1961',
          description:
            'In 1961, Charlayne Hunter and Hamilton Holmes enrolled as the first African American students at UGA. Mary Frances Early soon joined them when she enrolled as the university’s first African American graduate student and became the first to receive a UGA degree. Learn more about UGA’s early history in F.N. Boney’s book, A Pictorial History of the University of Georgia, 1984, University of Georgia Press.',
        },
    {
          year: '1980',
          description:
            'The Georgia Bulldogs completed a 12-0 season with a championship win over Notre Dame at the Sugar Bowl.',
        },
    {
          year: '2022',
          description:
            'On January 10th, the Georgia Bulldogs claimed their third football national championship, defeating the Alabama Crimson Tide 33-18.',
        },
      ],
    };
  },
  components: {
    Breadcrumb,
    PageHeader,
    // Figure,
    QuickFact,
    // Factoid,
    Section,
    // YoutubeVideo,
    // VideoBackground,
  },
};
</script>

<style scoped lang="scss">
.timeline {
  list-style: none;
  margin: 0 auto;
  padding: 0;
  font-family: $font-family-sans-serif;
  font-weight: 500;
  max-width: 900px;
  &__event {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: $spacer * 2;
    border-style: solid;
    border-color: $light;
    border-width: 0 0 4px 4px;
    padding: 0;
    &-year {
      font-size: 2rem;
      // padding: .5rem 1rem .5rem calc(1rem - 4px);
      padding: 0.5rem 1rem;
      background: lighten($beige, 10%);
      color: $red;
      font-weight: bold;
      font-family: $font-family-block;
      border-radius: 0 0 $border-radius * 2 0;
      // width: 20%;
    }
    &-description {
      flex: 1;
      padding: 2rem 2rem 2rem 0;
    }
    &:nth-child(odd) {
      flex-direction: row-reverse;
      border-width: 0 4px 4px 0;
      padding: 0;
      .timeline__event-year {
        text-align: right;
        border-radius: 0 0 0 $border-radius * 2;
        // padding: .5rem calc(1rem - 4px) .5rem 1rem;
        padding: 0.5rem 1rem;
      }
      .timeline__event-description {
        padding: 2rem 0 2rem 2rem;
      }
    }
    &:first-of-type {
      border-top-width: 4px;
    }
  }
}
</style>
